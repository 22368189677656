.App {
  min-height: 100vh;
}

.Auth-Layout {
  background: url('/assets/images/auth_background.png') no-repeat center center / cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.App-Navbar,
.MuiDrawer-paper {
  width: 240px;
  transform: width 1s;
}

.App-Navbar {
  .MuiDrawer-paper {
    padding: 24px 0px;
    background: linear-gradient(
      var(--neutral-light-300) 0%,
      var(--neutral-light-800) 320px,
      var(--neutral-light-800) 100%
    );
    border: none;
  }
}

.App-Header {
  padding: 16px 40px !important;
}

.App-Main {
  min-height: 100vh;
  margin-left: 240px;
  display: flex;
  flex-direction: column;
  transform: margin-left 1s;
}
.App-Content {
  flex: 1;
  background-color: var(--neutral-light-20);
  padding: 0px 40px 32px;
}

.Private-Layout--full-width {
  .App-Navbar,
  .MuiDrawer-paper {
    width: 0;
  }
  .App-Main {
    margin-left: 0;
  }
}
